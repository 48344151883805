import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  generateParamArray,
  generateSearchQuery,
} from '@app/core/helpers/params-generator';
import { IResponse } from '@app/core/models/response.model';
import { Observable } from 'rxjs';

import { IResponseSuccess } from '../core/models/response-sucess.model';
import { CheckCredentials } from '../models';

@Injectable({
  providedIn: 'root',
})
export class CredentialsCheckFrontendService {
  constructor(private http: HttpClient) {}

  checkFrontendCredentials(data: any): Observable<IResponse> {
    const { companiesIds, ...newData } = data;
    let companiesData = '';
    if (companiesIds && companiesIds.length) {
      companiesData = `&${generateParamArray('exlude_id', companiesIds)}`;
    }

    return this.http.get(
      `general/frontend/credentials_check?` +
        generateSearchQuery(newData) +
        companiesData,
    ) as Observable<IResponse>;
  }

  checkCredentials(data: {
    email?: string;
    username?: string;
  }): Observable<IResponseSuccess<CheckCredentials>> {
    return this.http.get(
      `general/credentials_check?` + generateSearchQuery(data),
    ) as Observable<IResponseSuccess<CheckCredentials>>;
  }
}
